import React, { Component, PropsWithChildren } from 'react';
import { SearchVariantFieldsFragment } from '../../../client/__generated__/graphql-client-types';

export const DEFAULT_VARIANT_GROUP_NUM = 1;

type SearchVariantContextState = {
	hoveredVariant: any;
	selectedVariant: any;
	setHoveredVariant: Function;
	setSelectedVariant: Function;
	variantGroupDisplayed: number;
	setVariantGroupDisplayed: Function;
};

const initialState: SearchVariantContextState = {
	hoveredVariant: undefined,
	selectedVariant: {},
	setHoveredVariant: () => {},
	setSelectedVariant: () => {},
	variantGroupDisplayed: DEFAULT_VARIANT_GROUP_NUM,
	setVariantGroupDisplayed: () => {}
};

export const SearchVariantContext = React.createContext<SearchVariantContextState>(initialState);

type VariantContextProviderProps = { variant?: SearchVariantFieldsFragment };
export class SearchVariantContextProvider extends Component<PropsWithChildren<VariantContextProviderProps>, SearchVariantContextState> {
	readonly state: SearchVariantContextState = {
		...initialState,
		selectedVariant: this.props.variant,
		setSelectedVariant: (selectedVariant: SearchVariantFieldsFragment) => {
			this.setState(() => ({ selectedVariant }));
		},
		setHoveredVariant: (hoveredVariant: SearchVariantFieldsFragment) => {
			this.setState(() => ({ hoveredVariant }));
		},
		setVariantGroupDisplayed: (variantGroupDisplayed: number) => {
			this.setState(() => ({ variantGroupDisplayed }));
		}
	};
	componentDidUpdate(prevProps) {
		if (prevProps.variant !== this.props.variant) {
			this.setState(() => ({ selectedVariant: this.props.variant }));
		}
	}
	render() {
		return <SearchVariantContext.Provider value={this.state}>{this.props.children}</SearchVariantContext.Provider>;
	}
}
