import { useLazyQuery } from '@apollo/client';
import React, { FunctionComponent, useEffect } from 'react';
import { Maybe, ProductViewEnum } from '../../../__generated__/graphql-client-types';
import { FEATURE_FLAGS } from '../../../constants/general';
import { SearchVariantContextProvider } from '../../../contexts/search/search-variant.context';
import { useCustomer } from '../../../hooks/apollo/customer/customer.hooks';
import { useSearchResults, UseSearchResultsPayload } from '../../../hooks/apollo/search/search.hooks';
import { useFeature } from '../../../hooks/features/features.hooks';
import { PRODUCT_ECO_REBATES } from '../../../queries/eco-rebates/eco-rebates.queries';
import { DropType } from '../../../types/search.types';
import { PageLoading } from '../../common-components/loading/loading.component';
import { SearchProductCard } from '../search-product-card/search-product-card.component';

export type SearchResultsProps = {
	viewType?: Maybe<ProductViewEnum>;
	dropType?: DropType;
	useResults?: () => UseSearchResultsPayload;
};

export const SearchResults: FunctionComponent<SearchResultsProps> = ({ viewType, dropType, useResults = useSearchResults }) => {
	const { results, previousResults, loading, page, isNonstock, isNewLookAndFeelProductCard, createGTMEvent } = useResults();
	const showResults = loading ? previousResults : results;

	const { data: customerData } = useCustomer();
	const customerZipCode = customerData?.customer.location.zipCode;

	const showPLPRebateBadging = useFeature(FEATURE_FLAGS.PLP_REBATE_BADGE);
	const [getRebates, { data: rebatesData }] = useLazyQuery(PRODUCT_ECO_REBATES);
	const rebateSkus = rebatesData?.currentEcoRebates.map((rebate) => rebate.sku) ?? [];
	const rebatesOnPage = rebateSkus.length;
	const checkForProductRebate = (variantId) => rebateSkus.includes(variantId.toString());

	useEffect(() => {
		if (showPLPRebateBadging) {
			const productIds = showResults?.products?.map((product) => product.id);
			if (!productIds?.length) {
				return;
			}
			void getRebates({ variables: { variantIds: productIds, zipCode: customerZipCode } });
		}
	}, [showResults, customerZipCode, showPLPRebateBadging, getRebates]);

	return (
		<>
			{showResults?.products && (
				<div className={`flex ${viewType === 'LIST' ? 'flex-column flex-row-ns' : ''} flex-wrap ml3-l`}>
					{showResults.products.map((product, index) => {
						if (product.variants) {
							const initialVariant = product.variants.find((v) => v.id === product.id) ?? product.variants[0];
							return (
								<SearchVariantContextProvider variant={initialVariant} key={`${initialVariant.id}-${index}`}>
									<SearchProductCard
										dropType={dropType}
										product={product}
										viewType={viewType}
										cardIndex={index}
										page={page}
										searchId={showResults.searchId}
										hasRebate={checkForProductRebate(product.id)}
										rebatesOnPage={rebatesOnPage}
										isNonstockSearch={isNonstock}
										isNewLookAndFeel={isNewLookAndFeelProductCard}
										createGTMEvent={createGTMEvent}
									/>
								</SearchVariantContextProvider>
							);
						}
						return null;
					})}
				</div>
			)}
			{loading && (
				<div className="absolute top-0 w-100 h-100 bg-theme-white-o70 z-1">
					<div className="sticky top-0">
						<PageLoading />
					</div>
				</div>
			)}
		</>
	);
};
